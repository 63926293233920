/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';

export default function NotFound() {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.location = '/';
  }

  return null;
}
